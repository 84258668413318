import React, { useState, useRef } from 'react'
import { Cell, Button } from 'zarm'
// import Chat from 'chat-react'
// import Chat from './chat/src'


const Live = () => {
    const [activeKey, setActiveKey] = useState('home')
    const [visible, setVisible] = useState(true)

    const [inputValue, setInputValue] = useState('')
    const [messages, setMessages] = useState([
      {
        timestamp: 1545925494422,
        userInfo: {
            avatar: "https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png",
            name: "京东",
            userId: "1544365758856"
        },
        value: "蒙牛 纯甄 常温风味酸牛奶 芒果百香果口味 200g*16 礼盒装\nhttps://u.jd.com/k2OiU4\nP价54.9元，下4件，8折叠加169-35，实付140.68元，折35.17元每件"
    },  {
        timestamp: 1545925534218,
        userInfo: {
            avatar: "https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png",
            name: "淘宝",
            userId: "1544365758856"
        },
        value: "<a href='https://u.jd.com/k2OiU4'>https://u.jd.com/k2OiU4</a>",
        error: true //设置消息状态为失败，显示错误状态图标
    }])
    const [timestamp, setTimestamp] = useState(new Date().getTime())
    const chat = useRef()

    const setInputfoucs = () => {
      chat.refs.input.inputFocus();  //set input foucus
    }
    const setScrollTop = () => {
      chat.refs.message.setScrollTop(1200);  //set scrollTop position
    }
    const sendMessage = (v) => {
      const {value} = v
      if (!value) return
      let newMessage = messages
      newMessage.push(v)
      // setState({messages, timestamp: new Date().getTime(), inputValue: ''})
      setMessages(messages)
      setTimestamp(new Date().getTime())
      setInputValue('')
    }
    const userInfo = {
      avatar: "http://img.binlive.cn/6.png",
      userId: "59e454ea53107d66ceb0a598",
      name: 'ricky'
    }

    return (
        <>
         <Cell
            description={
              <Button
                size="xs"
                onClick={() => {
                  setVisible(!visible);
                }}
              >
                { visible ? '隐藏' : '展示'}
              </Button>
            }
          >
            群消息直播
          </Cell>
        </>
    )
}

export default Live
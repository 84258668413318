import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import axios from 'axios'
import { SearchBar, Icon, Button, Toast, ActionSheet, NoticeBar, Cell, Pull, ImagePreview } from 'zarm'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import './style.css'
import { ip } from '../../utils/ip'


const qs = require('qs')

const MAX_DATASOURCE_LENGTH = 500

const REFRESH_STATE = {
    normal: 0,  // 普通
    pull: 1,    // 下拉刷新（未满足刷新条件）
    drop: 2,    // 释放立即刷新（满足刷新条件）
    loading: 3, // 加载中
    success: 4, // 加载成功
    failure: 5, // 加载失败
  };
  
  const LOAD_STATE = {
    normal: 0,   // 普通
    abort: 1,    // 中止
    loading: 2,  // 加载中
    success: 3,  // 加载成功
    failure: 4,  // 加载失败
    complete: 5, // 加载完成（无新数据）
  }
  const getRandomNum = (min, max) => {
    const Range = max - min;
    const Rand = Math.random();
    return (min + Math.round(Rand * Range));
  }
  
  const fetchData = (length, dataSource = []) => {
    let newData = [].concat(dataSource);
    const startIndex = newData.length;
    for (let i = startIndex; i < startIndex + length; i++) {
      newData.push(<Cell key={+i}>第 {i + 1} 行</Cell>);
    }
    return newData;
  }
  


const Messages = (props) => {
    let mounted = true;
    const {  loader, noData, noDataEle} = props
    const noDataElement = noDataEle || (<p className="noData-tips">没有更多数据了</p>)
    
    const [banner, setBanner] = useState('')
    const [avatar, setAvatar] = useState('')
    const FetchInfo = () => {
        let url = ip
        const params = {
            userinfo:1,
            extend:1,
        }
        axios.post(url, qs.stringify(params), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then(res => {
            // console.log(res.data)
            let data = JSON.parse(res.data.data)
            console.log(data)
            setBanner(decodeURIComponent(data.banner))
            setAvatar(decodeURIComponent(data.avatar))
        }).catch(err => console.log(err))
    }
    useEffect(() => {
        FetchInfo()
        console.log(dataSource.length)
    }, [])
    // first dataSource render
    const [dataSource, setDataSource] = useState([])
    const [isFirstRender, setIsFirsetRender] = useState(false)
    const [pageNumber, setPageNumber] = useState(2)
    // const [timeStamp, setTimeStamp] = useState(Date.now())
    let timeStamp = useRef(Date.now())
    // useEffect(() => {
    //     console.log('dataSource', dataSource)
    //     if(dataSource.length > 0) {
    //         let data = dataSource[dataSource.length - 1]
    //         setTimeStamp(data.timeStamp)
    //     }
    // }, [dataSource])
    const fetchCurrent = () => {
        let url = ip
        // const params = {
        //     newmsg: 1,
        //     extengd: 1
        // }
        const params = {
            updatemsg:1,
            pageNumber:1,
            extend:1,
        }
        axios.post(url, qs.stringify(params), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then(res => {
            // console.log(res.data)
            let data = JSON.parse(res.data.data)
            console.log(data.data)
            setDataSource(data.data)
            // setDataSource(data.data)
            // setPageNumber(preNumber => preNumber + 1)
            setIsFirsetRender(true)
        }).catch(err => console.log(err))
    }

    
    useEffect(() => {
        fetchCurrent()
        const timer = window.setInterval(() => {
            fetchNew()
        }, 5 * 1000)
        
        return () => {
            mounted = false;
            document.body.style.overflow = 'auto';
            window.clearInterval(timer)
        }
    }, [])

    //scroll to bottom
    const messagesEndRef = useRef(null)
    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ block: 'end', behavior: 'auto' })
        // var messageBody = document.querySelector('#root');
        // messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    }
    useEffect(() => {
        // console.log('dataSource', dataSource)
        // console.log(isFirstRender)
        if(isFirstRender) {
            setIsFirsetRender(false)
            // console.log(messagesEndRef)
            scrollToBottom()
        }
    }, [isFirstRender])

    const [isRefreshing, setIsRefreshing] = useState(false)
    const [shouldChangeTimeStamp,setShouldChangeTimeStamp] = useState(false)
    const fetchNew = () => {
        let url = ip
        // let timeStamp = Date.now()
        console.log(timeStamp)
        const params = {
            newmsg:1,
            timeStamp: timeStamp.current,
            extend:1
        }
        // console.log('dataSource', dataSource)
        axios.post(url, qs.stringify(params), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then(res => {
              console.log(res.data)
              if(res.data.data !== "") {

                let data = JSON.parse(res.data.data)
                console.log(data)
                // 1600067861024
                if(data.length !== 0) {
                    setShouldChangeTimeStamp(true)
                    setDataSource(prevDataSource => {
                        if(prevDataSource.length > MAX_DATASOURCE_LENGTH) {
                            window.location.reload()
                        }
                        return [...prevDataSource, ...data.data]
                    })
                    if(pageNumber < 3) {
                        setIsRefreshing(true)
                    }
              }
            }
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        if(shouldChangeTimeStamp) {
            timeStamp.current = Date.now()
            setShouldChangeTimeStamp(pre => !pre)
        }
    }, [shouldChangeTimeStamp])

    // 每5s定时刷新

    // scroll to load

    useEffect(() => {
        if(isRefreshing) {
            setIsRefreshing(pre => !pre)
            console.log('isRefreshing', isRefreshing, dataSource)
            scrollToBottom()
        }
    }, [isRefreshing])

    // format message
    const [imgsNumber, setImgNumber] = useState(0)
    const [tempImgsNumber, setTempImgsNumber] = useState(0)
    const onImgLoad = (e, len) => {
        console.log('pageNumber', pageNumber)
        if(pageNumber <= 2) {
            setImgNumber(preNumber => preNumber + len)
            setTempImgsNumber(preNumber => preNumber + 1)
            console.log('tempImgsNumber', tempImgsNumber, len, imgsNumber)
            if(tempImgsNumber === imgsNumber) {
                scrollToBottom()
            }
        }
    }
    const copyTKL = () => {
        Toast.show(
            <div className="box">
              <div className="box-text">
                淘口令已复制到粘贴板
              </div>
            </div>
          );
    }
    const formatMessage = (msg, shopType) => {
        let expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi
        let regex = new RegExp(expression)
        let newMsg = decodeURIComponent(msg)
        let urls = newMsg.match(regex) || []
        for(let url of urls) {
            let link = `<a class="message-item-content-a" href="${url}" target="_blank">${url}</a>`
            newMsg = newMsg.replace(url, link)
        }
        if(shopType == '1') {
          return (
            <CopyToClipboard text={newMsg} onCopy={copyTKL}>
                <div dangerouslySetInnerHTML={{__html: newMsg}}></div>
            </CopyToClipboard>
        ) 
 
        }
        return <div dangerouslySetInnerHTML={{__html: newMsg}}></div>
    }

    const [imgVisible, setImgVisible] = useState(false)
    const [commonImages, setCommonImages] = useState([''])
    const show = (url) => {
        setCommonImages([url])
        setImgVisible(true)
    }
    const hide = () => {
        setImgVisible(false)
    }
    const formatImg = (picUrls) => {
        let len = picUrls.length
        for(let url of picUrls) {
            if(!url || url === "") {
                len--
            }
        }
        return picUrls.map((item, index) => {
            if(item !== "") {
                return (<div key={index}>
                    <img onLoad={(e) => onImgLoad(e, len)} onClick={() => show(item)} style={{maxWidth: "50%", height: "auto"}} src={item}/>
                </div>)
            }
        })
    }
    
    const renderShopType = (shopType) => {
        switch(shopType) {
            case '1':
                return '淘宝（点一下文案复制淘口令）'
            case '2':
                return '京东'
            case '3':
                return '拼多多'
            case '4':
                return '羊毛线报'
            default:
                return '群直播'
        }
    }
    const renderMessageList = (data) => {
        return data.map((item, index) => {
            const {
                picUrl, shopType, msgTime, msg
              } = item
            let tempPicUrls = decodeURIComponent(picUrl)
            let picUrls = tempPicUrls.replace('[', '').replace(']', '').split(',')
            // const len = picUrls.length
            // setImgNumber(tempPicUrls.length)
            // console.log(picUrls)
            //   let picUrls = JSON.parse(newD)
            //   console.log(JSON.parse(aaa))
            return (<div key={index}>
                <div className={`message-item message-item-other`}>
                <div>
                    <img
                    className="message-item-avatar"
                    src={avatar}
                    />
                </div>
                <p className="message-item-info">
                    <span>{renderShopType(shopType)}</span>
                    <span>{msgTime}</span>
                </p>
                <div className="message-item-content">
                    {formatImg(picUrls)}
                    {formatMessage(msg, item.shopType)}
                </div>
                </div>
            </div>)
        })
    }

    const [showSearchBar, setShowSearchBar] = useState(false)
    // 搜索
    const [searchData, setSearchData] = useState([])
    const [isShopTypeShow, setIsShopTypeShow] = useState(false)
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [showSearchData, setShowSearchData] = useState(false)
    const [searchType, setSearchType] = useState({
        shopType: '',
        itemType: ''
    })
    const search = () => {
        if(searchType.shopType === '' && searchType.itemType === '') {
            setShowSearchData(false)
            return
        }
        let url = ip
        // console.log(searchType)
        const params = {
            shopType: searchType.shopType,
            itemType: searchType.itemType,
            extend: 1,
        }
        axios.post(url, qs.stringify(params), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then(res => {
            console.log('search', res.data)
            let data = JSON.parse(res.data.data)
            console.log('data', data)
            setSearchData(data.data)
        }).catch(err => console.log(err))
    }
    useEffect(() => {
        search()
    }, [searchType])
    // 关键词搜索
    const [word, setWord] = useState('')
    const [value, setValue] = useState('')
    const clear = () => {
        setValue('')
        setWord('')
        setShowSearchBar(false)
        setShowSearchData(false)
    }
    const onSubmit = (value) => {
        // console.log(e)
        setShowSearchData(true)
        setWord(value)
    }

    const serachByWord = () => {
        let url = ip
        const params = {
            search: word,
            extend: 1,
        }
        axios.post(url, qs.stringify(params), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then(res => {
            console.log('words', res.data)
            let data = JSON.parse(res.data.data)
            setSearchData(data.data)
        }).catch(err => console.log(err))
    }
    useEffect(() => {
        serachByWord()
    }, [word])
    //
    const showShopType = () => {
        // setIsShopTypeShow(true)
        setVisible1(true)
    }
    const showItemType = () => {
        setVisible2(true)
    }
    const [shopButtonName, setShopButtonName] = useState('商城')
    const [typeButtonName, setTypeButtonName] = useState('品类')
    const backToLive = () => {
        setShowSearchData(false)
        setShopButtonName('商城')
        setTypeButtonName('品类')
    }
    const BUTTONS = [
        {
            theme: 'danger',
            text: '全部',
            onClick: () => {
              setSearchType(pre => {
                  return {
                      ...pre,
                      shopType: ''
                  }
              })
              setShopButtonName('商城')
              setVisible1(false)
              setShowSearchData(true)
            },
          },
        {
          theme: 'danger',
          text: '淘宝',
          onClick: () => {
            setSearchType(pre => {
                return {
                    ...pre,
                    shopType: '1'
                }
            })
            setShopButtonName('淘宝')
            setVisible1(false)
            setShowSearchData(true)
          },
        },
        {
          theme: 'danger',
          text: '京东',
          onClick: () => {
            setSearchType(pre => {
                return {
                    ...pre,
                    shopType: '2'
                }
            })
            setShopButtonName('京东')
            setVisible1(false)
            setShowSearchData(true)
          },
        },
        {
          theme: 'danger',
          text: '拼多多',
          onClick: () => {
            setSearchType(pre => {
                return {
                    ...pre,
                    shopType: '3'
                }
            })
            setShopButtonName('拼多多')
            setVisible1(false)
            setShowSearchData(true)
          },
        },
        {
            theme: 'danger',
            text: '羊毛线报',
            onClick: () => {
            setSearchType(pre => {
                return {
                    ...pre,
                    shopType: '4'
                }
            })
            setShopButtonName('羊毛线报')
            setVisible1(false)
            setShowSearchData(true)
          },
          },
      ]
      

      const TYPEBUTTONS = [
        {
            theme: 'danger',
            text: '全部',
            onClick: () => {
                setSearchType(pre => {
                    return {
                        ...pre,
                        itemType: ''
                    }
                })
                setTypeButtonName('品类')
                setVisible2(false)
                setShowSearchData(true)
            },
        },
        {
          theme: 'danger',
          text: '食品饮料',
          onClick: () => {
            setSearchType(pre => {
                return {
                    ...pre,
                    itemType: '1'
                }
            })
            setTypeButtonName('食品饮料')
            setVisible2(false)
            setShowSearchData(true)
          },
        },
        {
          theme: 'danger',
          text: '生鲜水果',
          onClick: () => {
            setSearchType(pre => {
                return {
                    ...pre,
                    itemType: '2'
                }
            })
            setTypeButtonName('生鲜水果')
            setVisible2(false)
            setShowSearchData(true)
          },
        },
        {
          theme: 'danger',
          text: '美妆护肤',
          onClick: () => {
            setSearchType(pre => {
                return {
                    ...pre,
                    itemType: '3'
                }
            })
            setTypeButtonName('美妆护肤')
            setVisible2(false)
            setShowSearchData(true)
          },
        },
        {
            theme: 'danger',
            text: '手机数码',
            onClick: () => {
            setSearchType(pre => {
                return {
                    ...pre,
                    itemType: '4'
                }
            })
            setTypeButtonName('手机数码')
            setVisible2(false)
            setShowSearchData(true)
          },
        },
        {
            theme: 'danger',
            text: '其他分类',
            onClick: () => {
            setSearchType(pre => {
                return {
                    ...pre,
                    itemType: '5'
                }
            })
            setTypeButtonName('其他分类')
            setVisible2(false)
            setShowSearchData(true)
          },
        },
      ]


    // 上拉加载
    const pullRef = useRef();
    const [refreshing, setRefreshing] = useState(REFRESH_STATE.normal);

    
    const getScrollTop = () => {
        var scroll_top = 0;
        if (document.documentElement && document.documentElement.scrollTop) {
            scroll_top = document.documentElement.scrollTop;
        }
        else if (document.body) {
            scroll_top = document.body.scrollTop;
        }
        return scroll_top;
    }

    const [shouldScrollToCurrent, setShouldScrollToCurrent] = useState(false)
    const [tempScrollTop, setTempScrollTop] = useState(0)
    // 上拉加载数据
    const refreshData = () => {
        const { offsetTop } = messagesEndRef.current
        setTempScrollTop(offsetTop)
        // const offsetTop = window.scrollY
        // console.log('tempScrollTop', messagesEndRef.current.offsetTop)
        setRefreshing(REFRESH_STATE.loading);
        // setTimeout(() => {
        //     if (!mounted) return;
        //     setDataSource(dataSource.concat(dataSource));
        //     setRefreshing(REFRESH_STATE.success);
        // }, 2000);
        let url = ip
        const params = {
            updatemsg: 1,
            pageNumber: pageNumber,
            extend: 1,
        }
        axios.post(url, qs.stringify(params), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }).then(res => {
            // console.log(res.data)
            let data = JSON.parse(res.data.data)
            // console.log(data)
            // setDataSource(data.data)
            setDataSource(data.data.concat(dataSource));
            setRefreshing(REFRESH_STATE.success);
            setPageNumber(preNumber => preNumber + 1)
            // messagesEndRef.current.scrollIntoView({ block: 'end', behavior: 'auto' })
            setShouldScrollToCurrent(true)
        }).catch(err => console.log(err))
    }

    // scroll to current top
    useEffect(() => {
        if(shouldScrollToCurrent) {
            const currentScrollTop = messagesEndRef.current.offsetTop
            // console.log('currentScrollTop', currentScrollTop)
            window.scrollTo({
                behavior: "auto",
                top: currentScrollTop - tempScrollTop
            })
            setShouldScrollToCurrent(false)
        }
    }, [shouldScrollToCurrent])


    // useEffect(() => {
    //     // setDataSource(dataSource.concat(dataSource));

    //     return () => {
    //     mounted = false;
    //     document.body.style.overflow = 'auto';
    //     };
    // }, []);

    return (
        <div className="massage-container"  id="massage-container" style={{width: '100%', height: window.outerHeight}}>
            <div className="live-header top">
                <div className="live-title">群直播</div>
                {showSearchBar ? (<SearchBar
                    showCancel
                    placeholder="搜索"
                    cancelText="取消"
                    value={value}
                    onSubmit={onSubmit}
                    onChange={setValue}
                    onClear={() => clear()}
                    onCancel={() => clear()}
                    />)
                    :
                    <div className="search-icon" onClick={() => setShowSearchBar(true)}>
                        <Icon type="search" theme="primary" size="md" />
                    </div>}
            </div>
            <div className="live-ad top">
                <NoticeBar 
                    icon={<Icon type="broadcast" style={{ color: '#FF0036' }}/>}
                    theme="danger">{banner}</NoticeBar>
            </div>
            <div className="live-options top">
                {
                    showSearchData ? <Button icon={<Icon type="arrow-left" theme="success" />} onClick={backToLive} className="live-options-button" size="sm" theme="primary">返回</Button>: <></>
                }
                <Button onClick={showShopType} className="live-options-button" size="sm">{shopButtonName}</Button>
                <Button onClick={showItemType} className="live-options-button" size="sm" theme="primary">{typeButtonName}</Button>
            </div>
            {/* <div className="message-list-wrapper">
            {dataSource && dataSource.length > 0 ? renderMessageList(dataSource) : <>
                <p className="noData-tips">没有更多数据了</p>
            </>}
            </div> */}
            <div className="message-list-wrapper">
                { showSearchData ? 
                <Pull
                    ref={pullRef}
                    refresh={{
                        state: refreshing,
                        handler: refreshData,
                    }}
                >
                    {
                    searchData && searchData.length > 0 ? renderMessageList(searchData) : 
                    <><p className="noData-tips">没有更多数据了</p></>
                    }
                </Pull> :
                <Pull
                    ref={pullRef}
                    refresh={{
                        state: refreshing,
                        handler: refreshData,
                    }}
                >
                    {
                    dataSource && dataSource.length > 0 ? renderMessageList(dataSource) : 
                    <><p className="noData-tips">没有更多数据了</p></>
                    }
                </Pull>
            }
            </div>
            <ActionSheet
                visible={visible1}
                actions={BUTTONS}
                onMaskClick={() => setVisible1(!visible1)}
            />
            <ActionSheet
                visible={visible2}
                actions={TYPEBUTTONS}
                onMaskClick={() => setVisible2(!visible2)}
            />
            {/* <div className="unread-count-tips"></div> */}
            {/* dummy div to scroll bottom */}
            <div style={{ float:"left", clear: "both" }}
                ref={messagesEndRef}>
            </div>
            <ImagePreview visible={imgVisible} images={commonImages} onClose={hide} activeIndex={0} /> 
      </div>
    )
}

export default Messages
import React, { useEffect, useState } from 'react'
import { Icon, TabBar, Cell, Button } from 'zarm'
import {
    withRouter,
    useHistory
  } from "react-router-dom"

const TabIcon = Icon.createFromIconfont('//at.alicdn.com/t/font_1340918_lpsswvb7yv.js');

const TabBarRouter = () => {
  const [activeKey, setActiveKey] = useState('live');
  const [visible, setVisible] = useState(true);

  const history = useHistory()
  useEffect(() => {
    // console.log('history', window.location)
    setActiveKey(window.location.pathname.replace('/', ''))
  }, [])
  const changeRoute = (e) => {
    console.log(e)
    setActiveKey(e)
    let pathname = `/${e}`
    console.log(pathname)
    history.push(pathname)
  }
  return (<TabBar
    visible={visible}
    activeKey={activeKey}
    onChange={changeRoute}>
    <TabBar.Item
      itemKey="live"
      title={"群直播"}
      icon={<TabIcon type="home" />}
      badge={{ shape: 'dot' }}
    />
      <TabBar.Item
        itemKey="discount"
        title={"日常优惠"}
        icon={<TabIcon type="insurance" />}
        // badge={{ shape: 'circle', text: '3' }}
      />
    <TabBar.Item
      itemKey="buy"
      title="每日必买"
      icon={<TabIcon type="user" />}
    />
  </TabBar>)
}

export default withRouter(TabBarRouter)
import React, { useState, useEffect } from 'react'
import { Cell, Button, Toast, Icon } from 'zarm'
import axios from 'axios'
import { ip } from '../utils/ip'
import './Discount.css'
import { CopyToClipboard } from 'react-copy-to-clipboard'


const qs = require('qs')
const Discount = () => {
    let mounted = true
    const [info, setInfo] = useState({})
    const FetchInfo = () => {
      let url = ip
      const params = {
          userinfo:1,
          extend:1,
      }
      axios.post(url, qs.stringify(params), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }).then(res => {
          // console.log(res.data)
          let data = JSON.parse(res.data.data)
          // console.log(data)
          setInfo(data)

      }).catch(err => console.log(err))
    }
    let ua = window.navigator.userAgent.toLowerCase()
    const [eleUrl, setEleUrl] = useState('')
    const parseTKL = (tkl) => {
      // tkl = '￥zJrnc4297NZ￥'
      let url = `http://tools.mkstone.club/json/${tkl}`
      axios.get(url).then(res => {
        console.log(res.data)
        if(mounted) {
          setEleUrl(res.data.url)
        }
      }).catch(err => console.log(err))
    }
    const [isWx, setIsWx] = useState(false)
    useEffect(() => {
      if(ua.match(/MicroMessenger/i)=="micromessenger") { // ios和andoid通用判断微信
        setIsWx(true)
      }
      FetchInfo()
      return () => {
        mounted = false
      }
    }, [])
    useEffect(() => {
      if(ua.match(/MicroMessenger/i)=="micromessenger") { // ios和andoid通用判断微信
        // 复制淘口令
      } else {
        if(info.ele) {
          parseTKL(decodeURIComponent(info.ele))
        }
      }
    }, [info])
    const copyTKL = () => {
      Toast.show(
        <div className="box">
          <div className="box-text">
            淘口令已复制到粘贴板
          </div>
        </div>
      );
    }
    return (
      <div className="discount-container"  id="massage-container" style={{width: '100%', height: window.outerHeight}}>
        <div className="live-header top">
          <div className="live-title">日常优惠</div>
        </div>
        <div className="discount-list">
          <div>
            <a href={decodeURIComponent(info.qqgroup)} target="_blank">
              <img src="http://static.shitouboy.com/qqgroup.png"/>
            </a>
          </div>
          <div>
            {!isWx ? <a href={eleUrl} target="_blank">
              <img src="http://static.shitouboy.com/eleme.png"/>
            </a>
            : 
            <CopyToClipboard text={decodeURIComponent(info.ele)} onCopy={copyTKL}>
              <img src="http://static.shitouboy.com/eleme.png"/>
            </CopyToClipboard>
            }
            
          </div>
          <div>
          <a href={decodeURIComponent(info.cms)} target="_blank">
            <img style={{height: 'auto'}} src="http://static.shitouboy.com/chaquan.png"/>
          </a>
          </div>
        </div>
      </div>
    )
}

export default Discount
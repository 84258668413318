import React, { useState } from 'react';
import './App.css';
import 'zarm/dist/zarm.min.css'
import { Icon, TabBar, Cell, Button } from 'zarm';
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter
} from "react-router-dom"
import Live from './Live/Live'
import Discount from './Discount/Discount'
import Messages from './Live/messages/Messages'
import TabBarRouter from './TabBarRouter'
import Buy from './Buy/Buy'

const App = () => {
  return (
    <Router>
      <Route path="/live">
        <Messages />
      </Route>
      <Route path="/discount">
        <Discount />
      </Route>
      <Route path="/buy">
        <Buy />
      </Route>
      <Route path="/" exact>
        <Messages />
      </Route>
      <TabBarRouter />
    </Router>  
  );
}
export default App;

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Loading } from 'zarm'
import { ip } from '../utils/ip'
import './Buy.css'

const qs = require('qs')
// const data = [{
//   "msg":"护舒宝(Whisper)  卫生巾 日用/夜用284mm 24片",
//   "price":"11",
//   "pic":"http://img14.360buyimg.com/n1/jfs/t1/146400/30/8779/87600/5f6429cdE705d8ee2/9a25f159532a4e88.jpg",
//   "url":"https://u.jd.com/OS7Jtm"

// },{
//   "msg":"护舒宝(Whisper)  卫生巾 日用/夜用284mm 24片",
//   "price":"11",
//   "pic":"http://img14.360buyimg.com/n1/jfs/t1/148815/3/8705/78817/5f641a4cEd9839262/f6f9f9455ed69284.jpg",
//   "url":"https://u.jd.com/OS7Jtm"

// },{
//   "msg":"拍2件，可撕式粘毛器 \nhttps://u.jd.com/OL12Kt \nP-5金，9.9得2手柄6卷纸",
//   "price":"11",
//   "pic":"http://img14.360buyimg.com/n1/jfs/t1/131115/18/6369/152948/5f2d8f84E0e0b3cfe/7a9e32e2eee3ead1.jpg",
//   "url":"https://u.jd.com/OS7Jtm"

// }]
const Buy = () => {
    const [data, setData] = useState([])
    const [jfid, setJfid] = useState(null)
    const FetchInfo = () => {
      let url = ip
      const params = {
          userinfo:1,
          extend:1,
      }
      axios.post(url, qs.stringify(params), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }).then(res => {
          // console.log(res.data)
          let data = JSON.parse(res.data.data)
          // console.log(data)
          setJfid(data.jfid)

      }).catch(err => console.log(err))
    }
    useEffect(() => {
      FetchInfo()
    }, [])
    const fetchBuyList = (jfid) => {
      Loading.show()
      let url = 'http://134.175.182.249:8806/api/v1/tkapi/zhibo/jdswap'
      const params = {
        jfid: jfid
      }
      axios.post(url, qs.stringify(params), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then(res => {
        // console.log(res.data)
        let data = res.data.data
        console.log(data)
        setData(data)
        Loading.hide()
        // setJfid(data.jfid)
      }).catch(err => console.log(err))

    }
    useEffect(() => {
      if(jfid) {
        fetchBuyList(jfid)
      }
    }, [jfid])
    return (
    <div className="discount-container"  id="massage-container" style={{width: '100%', height: window.outerHeight}}>
        <div className="live-header top">
          <div className="live-title">每日必买</div>
        </div>
        <div className="buy-list">
            {data.map((item, index) => (
              <a  key={index} href={item.url} target="_blank">
              <div className="buy-item">
                <img src={item.pic} />
                <div className="buy-msg-wrapper">
                  <div className="buy-msg">{item.msg}</div>
                  <div className="buy-price">
                    券后价  ￥{parseFloat(item.price).toFixed(2)}
                  </div>
                </div>
              </div>
              </a>
            ))}
          </div>
    </div>
    )
}

export default Buy